export const fetchLatestReleaseTag = async (): Promise<string | null> => {
  try {
    const response = await fetch(
      'https://us-central1-sds-scan.cloudfunctions.net/github-release-info',
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data.release;
    } else {
      console.error('Failed to fetch the latest release:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching the latest release:', error);
    return null;
  }
};

export const isLocalStorageAvailable = (): boolean => {
  try {
    const test = '__storage_test__';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const checkForNewRelease = async () => {
  if (!isLocalStorageAvailable()) {
    console.warn('LocalStorage is not available. Skipping release check.');
    return;
  }

  const latestReleaseTag = await fetchLatestReleaseTag();
  if (!latestReleaseTag) return;

  const storedReleaseTag = localStorage.getItem('release');
  if (storedReleaseTag !== latestReleaseTag) {
    localStorage.setItem('release', latestReleaseTag);
    window.location.reload();
  }
};
